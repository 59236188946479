import { Box } from "@mui/material";
import { PieChart, Pie, Cell, Legend } from "recharts";

const COLORS = ["#ffc50d", "#fd5ba6", "#2ae345", "#0dfff0", "#b452d7", "#4a76fd", "#fe7968", "#27af6f"];

export default function Chart(props:any){
    return(
        <>
        <Box sx={{alignContent:'left'}} >
             <PieChart width={450} height={250}>
                <text x={250} y={125} textAnchor="middle" dominantBaseline="middle" fontWeight={700}>{props.chartTitle}</text>
                <Legend layout="vertical" verticalAlign="middle" align="left"/>
                <Pie
                data={props.data}
                innerRadius={55}
                outerRadius={90}
                dataKey="value"
                >
                {props.data.map((entry:any, index:number) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                ))}
                </Pie>
            </PieChart>
        </Box>
           
        </>
    )
}