import { useEffect, useState } from "react";
import "react-multi-date-picker/styles/colors/teal.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { DateObject } from "react-multi-date-picker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMomentJalaali } from '@mui/x-date-pickers/AdapterMomentJalaali';

export default function CustomDatePicker({ onChangeDate }: { onChangeDate: (date: string| Date) => void }) {
  const [value, setValue] = useState<DateObject | any | null>(null);

  useEffect(() => {
  
    // onChangeDate(moment.utc(new Date(value)).locale("fa").local().format("YYYY-MM-DD"));
    onChangeDate(new Date(value))
    console.log(value)
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterMomentJalaali}>
    <DateCalendar 
      sx={{height:'285px'}}
      value={value}
      onChange={setValue}
      className="teal"
    />
  </LocalizationProvider>    
  );
}
 