import { Box, Button, Grid, Typography } from "@mui/material";
import { adsType, changeActive } from "api/ads";
import { BackButton } from "components";
import PageContainer from "components/PageContainer";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import stop from "assets/Stop_Sign.svg";
import tick from "assets/Circle_Check.svg";
import useSWR from "swr";
import moment from "jalali-moment";
import adLink from "assets/ads-link.svg";
import { toast } from "react-toastify";

type AdListDetailsContext = [adsType[]];

export default function AdListsDetails() {
  const fakeAd: any[] = [
    {
      _id: "66a7a0e6656b5f50dc7fafce",

      provider: "yektanet",
      type: "banner",
      bannerAds: {
        code: '<div id="mediaad-nwJWr"></div>',
        width: 100,
        height: 100,
        createdAt: "2024-07-29T14:02:14.353Z",
        updatedAt: "2024-07-29T14:02:14.353Z",
      },
      location: "site_left",
      weight: 0,
      active: true,
      adsStats: [
        {
          view: 0,
          click: 0,
          startTime: "2024-07-29T14:02:14.349Z",
          endTime: null,
          income: 0,
          _id: "66a7a0e6656b5f50dc7fafcf",
        },
      ],
      totalAdsStats: {
        startTime: "2024-07-29T14:02:14.349Z",
        endTime: null,
        _id: "66a7a0e6656b5f50dc7fafcf",
      },
      createdAt: "2024-07-29T14:02:14.353Z",
      updatedAt: "2024-07-29T14:02:14.353Z",
      __v: 0,
    },
  ];
  const { data: ads, mutate, isLoading } = useSWR<{ ads: adsType[]; count: number }>("/ads");
  const [filterAdsById, setFilterAdsById] = useState<adsType[]>(fakeAd);
  const [isClicked, setIsClicked] = useState(0);
  const navigate = useNavigate();
  function handleClick(link: string, idx: number) {
    setIsClicked(idx);
    navigate(link);
  }
  const { id } = useParams();
  useEffect(() => {
    ads && ads.ads && ads.ads.length !== 0
      ? setFilterAdsById(ads?.ads.filter((ad: adsType) => ad?._id === id))
      : setFilterAdsById(fakeAd);
  }, [id,ads]);
  async function handleChangeActive() {
    try {
      if (id) await changeActive({ active: !filterAdsById[0].active, weight: filterAdsById[0].weight }, id);

      toast.success("done");
      mutate()
   
    } catch (error: any) {
      toast.error("reject");
    }
  }
  const infoList = [
    {
      title: "تاریخ ثبت تبلیغ",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>
          {`${moment.utc(filterAdsById[0].createdAt).locale("fa").format(" YYYY/MM/DD")}`}
        </Typography>
      ),
    },
    {
      title: "اخرین به روز رسانی",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>
          {`${moment.utc(filterAdsById[0].updatedAt).locale("fa").format(" YYYY/MM/DD")}`}
        </Typography>
      ),
    },
    {
      title: "وضعیت",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>{filterAdsById[0].active ? "فعال" : "غیر فعال"}</Typography>
      ),
    },
    {
      title: "لینک تبلیغ",
      content: (
        <Box sx={{ display: "flex", width: "80px", alignItems: "center" }}>
          <img src={adLink} alt="" />{" "}
          <a href={filterAdsById[0].type === 'video' ? filterAdsById[0]?.videoAds?.link : filterAdsById[0]?.bannerAds?.link} style={{ paddingRight: "0.5rem", textDecoration: "none", color: "#4A76FD" }}>
            کلیک کنید
          </a>{" "}
        </Box>
      ),
    },
  ];
  const list = [
    { title: "جزییات تبلیغ ", link: "detail" },
    { title: "آمار تبلیغ ", link: "statistics" },
  ];

  return (
    <PageContainer>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          px: 2,
          py: 2,
          mb: 2,
        }}
      >
        <BackButton back={() => navigate('/manageAdvertisement/adsList')}/>
        <Grid container sx={{ py: 3 }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>{`#${filterAdsById[0]._id}`}</Typography>
            <Button
              onClick={handleChangeActive}
              startIcon={filterAdsById[0].active ? <img src={stop} alt="" /> : <img src={tick} alt="" />}
              sx={{
                bgcolor: !filterAdsById[0].active ? "green" : "#F84E58",
                color: "white",
                px: filterAdsById[0].active ? 2.5 : 4,

                borderRadius: "6px",
                fontSize: "14px",
                "&:hover": { backgroundColor: !filterAdsById[0].active ? "#0cac00" : "#bd020d" },
                transition: "background 1s ",
              }}
            >
              {filterAdsById[0].active ? " غیر فعال" : "فعال"}
            </Button>
          </Grid>
          <Grid item xs={4}>
            {infoList.map((info: any, index: number) => {
              return (
                <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: "14px", py: 0.5 }}>{info.title}</Typography>
                  {info.content}
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} sx={{ borderBottom: "1px solid #C0C1CE", display: "flex", gap: 3 }}>
            {list.map((i: any, idx: number) => {
              return (
                <Box
                  onClick={() => handleClick(i.link, idx)}
                  key={idx}
                  sx={{
                    borderRadius: "2px",
                    borderBottom: isClicked === idx ? "3px solid blue" : "1px solid transparent",
                    cursor: "pointer",
                    fontSize: "14px",
                    py: 2,
                    transition: "border 0.2s  allow-discrete",

                    fontWeight: isClicked === idx ? 600 : "unset",
                    color: isClicked === idx ? "#4A76FD" : "unset",
                  }}
                >
                  {i.title}
                </Box>
              );
            })}
          </Grid>
        </Grid> 
        <Outlet context={[filterAdsById] as AdListDetailsContext} />
      </Box>
    </PageContainer>
  );
}
