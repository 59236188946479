import { Box } from "@mui/material";
import CustomBarChart, { DataItem } from "components/share/barChart";
import StateTable from "./table";
import GeneralStat from "./general";

const data: DataItem[] = [
  { value: "01/06/24", displayCount: 75000, clickCount: 13000, income: 26000000 },
  { value: "01/06/25", displayCount: 65000, clickCount: 11000, income: 22000000 },
  { value: "01/06/26", displayCount: 40000, clickCount: 7000, income: 14000000 },
  { value: "01/06/27", displayCount: 50000, clickCount: 9000, income: 18000000 },
  { value: "01/06/28", displayCount: 80000, clickCount: 14000, income: 28000000 },
  { value: "01/06/29", displayCount: 80000, clickCount: 14000, income: 28000000 },
  { value: "01/06/30", displayCount: 85000, clickCount: 15000, income: 30000000 },
  { value: "01/06/31", displayCount: 80000, clickCount: 14000, income: 28000000 },
];

export default function Statistics() {
  const getIntroOfPage = (label: string) => {
    console.log(label);
    return label;
    // ... your getIntroOfPage logic here
  };

  return (
    <>
      <GeneralStat />
      <StateTable />
      {/* <Box sx={{ maxWidth: "1000px", margin: "0px auto", padding: "5px 20px" }}>
        <CustomBarChart
          xAxisTitle={"زمان"}
          yAxisTitle={"نمایش تبلیغ"}
          barColor={"#2A41E7"}
          tooltipColor={["#2A41E7", "#FFC706", "#FE7968"]}
          value={data}
          title="نمایش تبلیغ"
        />
        <CustomBarChart
          xAxisTitle={"زمان"}
          yAxisTitle={"کلیک تبلیغ"}
          barColor={"#FFC706"}
          tooltipColor={["#2A41E7", "#FFC706", "#FE7968"]}
          data={data}
          title={"کلیک تبلیغ"}
        />
        <CustomBarChart
          xAxisTitle={"زمان"}
          yAxisTitle={"درآمد تبلیغ"}
          barColor={"#FE7968"}
          tooltipColor={["#2A41E7", "#FFC706", "#FE7968"]}
          data={data}
          title={"درآمد تبلیغ"}
        />
      </Box> */}
    </>
  );
}
