import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import PageContainer from "components/PageContainer";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { updateCompaniesInfo } from "api/adCompanies";
import { Select } from "components";
import ReplayIcon from '@mui/icons-material/Replay';
import useSWR from "swr";
import { useEffect } from "react";

export default function CompanyInfo() {
  const { id } = useParams();
  const{data: companyDetail}=useSWR(`/ads/company/${id}`)
  const{data: provincesList}=useSWR('/provinces?limit=35&sortBy=name&sortOrder=1')
  const{data: citiesList}=useSWR(`/cities?limit=400&sortBy=name&sortOrder=1`)
  const { handleSubmit, getFieldProps, errors, touched, setFieldValue, isSubmitting, setSubmitting, values } =
  useFormik({
    initialValues: {
      rank: '',
      callNumber: '',
      emailAddress: '',
      provinceId: '',
      cityId: '',
      siteAddress: '',
    },
    onSubmit: async (data) => {
      try {
        if (data && id) await updateCompaniesInfo(id, data);
        toast.success("done");
      } catch (error: any) {
        console.log("error", error);
        toast.error("reject");
      }
    },
  });

  useEffect(()=>{
    setFieldValue("rank", companyDetail?.rank);
    setFieldValue("callNumber", companyDetail?.callNumber);
    setFieldValue("emailAddress", companyDetail?.emailAddress);
    setFieldValue("provinceId", companyDetail?.province._id);
    setFieldValue("cityId", companyDetail?.city._id);
    setFieldValue("siteAddress", companyDetail?.siteAddress);
  }, [companyDetail]);

  const infoList = [
    {
      title: " درجه شرکت",
      content: (
        <Select {...getFieldProps("rank")}  sx={{width:"200px", textAlign:"center"}}>
          {['A', 'B', 'C', 'D'].map((i: string) => {
            return <MenuItem value={i.toLowerCase()}>{i}</MenuItem>;
          })}
        </Select>
      ),
    },
    {
      title: "شماره تماس شرکت",
      content: (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <input {...getFieldProps("callNumber")} style={{width:"400px", height:"50px", borderColor:"#D3D3D3", borderRadius:'5px', paddingRight:"10px"}}/>
        </Box>
      ),
    },
    {
      title: "آدرس ایمیل ",
      content: (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <input {...getFieldProps("emailAddress")} style={{width:"400px", height:"50px", borderColor:"#D3D3D3", borderRadius:'5px', paddingRight:"10px"}}/>,
        </Box>)
      
    },
    {
      title: "استان ",
      content: (
        <Select {...getFieldProps("provinceId")} sx={{width:"400px", pr:"10px"}}>
          {
            provincesList?.provinces.map((province: any, i: number)=>{return <MenuItem key={i} value={province._id}>{province.name}</MenuItem>})
          }
        </Select>
      ),
    },
    {
      title: "شهر",
      content: (
        <Select {...getFieldProps("cityId")} sx={{width:"400px", pr:"10px"}} placeholder="انتخاب کنید" disabled={!values.provinceId}>
          {
            citiesList?.cities.filter((city: any)=>city.province._id === values.provinceId).map((city: any, i: number)=>{return <MenuItem key={i} value={city._id}>{city.name}</MenuItem>})
          }
        </Select>
      ),
    },
    {
      title: " آدرس سایت",
      content: (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <input {...getFieldProps("siteAddress")} style={{width:"400px", height:"50px", borderColor:"#D3D3D3", borderRadius:'5px', paddingRight:"10px"}}/>
        </Box>
      ),
    },
    {
      title: "",
      content: (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button 
            type="submit" 
            sx={{
              ":hover": {bgcolor:"grey", },
              borderRadius:"4px",
              bgcolor:"#333337", 
              color:"white", 
              py:"10px", 
              width:"200px", 
              mt:"40px",
              pr:"10px",
            }}
          >
                <ReplayIcon sx={{color:"white", mr:"10px", fontSize: 20}}/>بروز رسانی اطلاعات
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <PageContainer noPadding={true}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          sx={{
            backgroundColor: "white",
            py: 2,
            mb: 2,
          }}
        >
          <Grid item xs={5}>
            {infoList.map((info: any, index: number) => {
              return (
                <Box key={index} sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
                  <Typography sx={{ fontSize: "14px", py: 0.5 }}>{info.title}</Typography>
                  <Box sx={{ width: "120px", display: "flex", justifyContent: "start" }}>{info.content}</Box>
                </Box>
              );
            })}
          </Grid>{" "}
        </Grid>
      </form>
    </PageContainer>
  );
}
