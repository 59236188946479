export const ROUTES: { [key: string]: any } = {
  DASHBOARD: "/dashboard",
  HOME: "/",
  USERS_INDEX: "/users",
  USERS_BASE: "/users/:role",
  USERS: "/users/user",
  DEVELOPERS: "users/publisher",
  AVATARS: "/avatars",
  CATEGORIES: "/categories",
  LEAGUESLIST: "/leagues",
  LEAGUESMANAGMENT: "/manageLeagues",
  LEAGUESBANNER: "/manageLeagues/banner",
  LEAGUESSLIDER: "/manageLeagues/slider",
  NEWLEAGUESLIDER: "/manageLeagues/slider/new-slider",
  NEWLEAGUEBANNER: "/manageLeagues/banner/new-banner",
  GAMES: "/games",
  LEAGUES: "/leagues",
  INNERLEAGUE: "/leagues/:id",
  DETAILS: "details",
  COMMINUCATION: "comminucations",
  USERSREPORTS: "usersReports",
  BLOCKEDUSERS: "blockedUsers",
  SCORBOARD: "scorBoard",
  BRANDS: "/brands",
  ACHIEVEMENTS: "/achievements",
  SUPPORT: "/support",
  NOTIFICATIONS: "/notifications",
  REPORTS: "/reports",
  FINANCE: "/finance",
  SETTINGS: "/settings",
  USER: "/users/user/:id",
  PUBLISHER: "users/publishers/user/:id",
  USER_GAMES_BASE: "/users/:id/games",
  USER_TICKETS_BASE: "/users/:id/tickets",
  GAME: (gameId: string) => `/games/${gameId}`,
  USER_TICKETS: (userId: string) => `/users/${userId}/tickets`,
  USER_GAMES: (userId: string) => `/users/${userId}/games`,
  TICKET: (ticketId: string) => `/tickets/${ticketId}`,
  USER_TICKET_BASE: "users/user/tickets/:id/:userId",
  NEW_GAME: "/games/new-game",
  GAME_BASE: "/games/:gameId/:versionId",
  PUBLISHED_GAME: "/innerGames/:gameId/:versionId",
  PUBLISHED_GAME_DETAILS: "details",
  PUBLISHED_GAME_STATISTICS: "statistics",
  PUBLISHED_GAME_VERSIONS: "versions",
  PUBLISHED_GAME_ADVERTISEMENT: "advertisement",
  PUBLISHED_GAME_HISTORY: "history",
  PUBLISHED_GAME_PREVIEW: "preview",
  ADVERTISEMENT: "manageAdvertisement",

  ADS_STATISTICS: "adsStatistics",
  ADS_COMPANY: "adsCompanies",
  ADS_COMPANIES_DETAILS: `manageAdvertisement/adsCompanies/:id`,
  ADS_COMPANIES_DETAILS_DETAILS: `detail`,
  ADS_COMPANIES_DETAILS_RELATED_PEOPLE: `relatedPeople`,
  ADS_COMPANIES_DETAILS_STATISTICS: `statistics`,

  ADS_LIST: "adsList",
  ADS_LIST_DETAILS: `manageAdvertisement/adsList/:id`,
  ADS_LIST_DETAILS_DETAILS: `detail`,
  ADS_LIST_DETAILS_STATISTICS: `statistics`,

  NEW_LEAGUE: "/leagues/new-league",
  TICKETSCATEGORIES: "/ticketscategories",
  TAGS: "/tags",
};
