import { format } from "date-fns";
import { GAME_STATUS } from "types/game";
import { formatD } from "utils/date";

export const SWR_KEYS = {
  USERS: (role: string) => `/users?role=${role}`,
  USER_GAMES: (id?: string) => id && `/games/${id}`,
  USER_TICKETS: (id?: string) => id && `tickets?userId=${id}`,
  TICKET: (id?: string) => id && `tickets?ticketId=${id}`,
  GAMES: (status: GAME_STATUS, searchText: string,page:number) => status==="draft" ?`/game/version?publishAfter=${formatD(new Date())}`: `/game/version?publishStatus=${status}&start=${(page-1)*10}&limit=10&searchText=${searchText}`,
  LEAGUES: (tabValue: "active" | "lastleagues" | "upcomming") =>
    tabValue === 
  "active"
      ? `/leagues?endDate=
      ${format(new Date(), "yyyy-MM-dd")}&startDate=${format(new Date(), "yyyy-MM-dd")}`
      : tabValue === "lastleagues"
      ? `/leagues?endDate=
      ${format(new Date(), "yyyy-MM-dd")}`
      : `/leagues?startDate=${format(new Date(), "yyyy-MM-dd")}`,
};

//SWR_KEYS.GAMES(GAME_STATUS.DONE, searchValue)
