import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { BackButton, DatePicker, Img, TextField, TimePicker } from "components";
import {
  Box,
  Button,
  Autocomplete,
  MenuItem,
  Typography,
  Grid,
  FormHelperText,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DateObject } from "react-multi-date-picker";
import PageContainer from "components/PageContainer";
import LeagueUploader from "components/uploader/leagueUploader";
import { useEffect, useState } from "react";
import { SubItemContainer } from "pages/newLeague/components/NewLeagueItems";
import { formatD, formatDateObjToTime, formatJallali } from "utils/date";
import Container from "./container";
import { createSlider, ISliderType } from "api/league";
import { toast } from "react-toastify";
import useNewSlider from "./useNewSlider";
import { combineDateTime } from "utils/timeCombiner";

const NewLeagueSlider = ({ type }: { type: "slider" | "banner" }) => {
  const { t } = useTranslation();
  let name = type === "banner" ? "بنر" : "اسلایدر";
  const [disable, setDisable] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<"disableDatePickers" | "enableDatePickers">("disableDatePickers");
  const handleChangeRadioValue = (event: any) => {
    setRadioValue(event.target.value);
  };
  const { leagueList, onDateChange, leagues } = useNewSlider();
  const [leagueDate, setLeagueDate] = useState({
    startDate: "2024-01-04T15:29:11.524Z",
    endDate: "2024-01-04T15:29:11.524Z",
  });
  const [value, setValue] = useState<{
    leagueId: string;
    leagueTitle: string;
    leagueCoverImage: string;
  }>({ leagueId: "", leagueTitle: "", leagueCoverImage: "" });

  const [inputValue, setInputValue] = useState<string>(value?.leagueTitle);
  const initialValues: ISliderType = {
    type: type,
    leagueId: "",
    imageId: "",
    startDate: new Date(),
    endDate: new Date(),
    order: 1,
    active: true,
    startTime: "04:05",
    endTime: "04:05",
  };

  const { values, setFieldValue, handleSubmit, errors, resetForm, touched } = useFormik({
    initialValues: initialValues,
    // enableReinitialize: true,
    onSubmit: async (values: any) => {
      let startdate = combineDateTime(values.startTime, formatD(new Date(values.startDate)));
      let enddate = combineDateTime(values.endTime, formatD(new Date(values.endDate)));
      const data = {
        ...values,
        startDate: startdate,
        endDate: enddate,
      };
      try {
        await createSlider(data);
        toast.success("با موفقیت ساخته شد");
      } catch (error: any) {
        toast.error("مشکلی رخ داده است");
      } finally {
        resetForm({
          values: {
            ...values,
            startDate: "",
            endDate: "",
            leagueId: "",
          },
        });
      }
    },
  });

  useEffect(() => {
    const currentSelectedLeague = leagues?.find((i: any) => i._id === value.leagueId);
    currentSelectedLeague &&
      setLeagueDate({ startDate: currentSelectedLeague?.startDate, endDate: currentSelectedLeague?.endDate });
    setFieldValue("startDate", currentSelectedLeague?.startDate);
    setFieldValue("endDate", currentSelectedLeague?.endDate);
  }, [leagues, value]);
  return (
    <PageContainer>
      <Grid
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          mb: "2rem",
          minheight: "90rem",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1rem 0rem 1rem 1rem",
            }}
          >
            <BackButton />
          </Box>
          <Box sx={{ display: "flex", my: 2 }}>
            <Typography
              sx={{
                fontWeight: 600,
                ml: 3,
              }}
            >
              {`افزودن به عنوان   ${name}`}
            </Typography>
          </Box>

          <Box>
            <Container title="chooseActiveLeague">
              <Autocomplete
                id="leagues"
                sx={{ width: "100%" }}
                options={leagueList}
                value={value}
                inputValue={inputValue}
                onChange={(event: any, val: any) => {
                  setFieldValue("leagueId", val?.leagueId);
                  setValue(val);
                }}
                onInputChange={(event: any, newInputValue: any) => setInputValue(newInputValue)}
                renderInput={(params) => <TextField error={Boolean(errors.leagueId || touched.leagueId)} {...params} />}
                getOptionLabel={(option: any) => option.leagueTitle}
                renderOption={(props, option: any) => (
                  <MenuItem
                    {...props}
                    key={option?.leagueId}
                    value={option?.leagueId}
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      opacity: radioValue === "disableDatePickers" ? 0.5 : 1,
                    }}
                  >
                    <Img
                      src={option?.leagueCoverImage}
                      alt={""}
                      sx={{
                        width: "3rem",
                        height: "3rem",
                        borderRadius: "0.5rem",
                      }}
                    />

                    <Typography>{option.leagueTitle}</Typography>
                  </MenuItem>
                )}
              />
            </Container>
            <Box sx={{ display: "flex", width: "700px", pt: 3 }}>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 500, color: "#979797", px: 3 }}>
                {t("leagueSDate")}
              </Typography>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 500, px: 15.5 }}>
                {formatJallali(leagueDate?.startDate)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", width: "700px", pt: 3 }}>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 500, color: "#979797", px: 3 }}>
                {t("leagueEndLeague")}
              </Typography>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 500, px: 15.5 }}>
                {" "}
                {formatJallali(leagueDate?.endDate)}
              </Typography>
            </Box>
            <Divider sx={{ py: 2 }} />
            <Container title="افزودن تصویر">
              <LeagueUploader
                type="image"
                fieldTitle="imageId"
                onChange={(event, a: string | boolean, b: any) => setFieldValue(event, a)}
              />
              {errors.imageId && <FormHelperText>فیلد اجباری</FormHelperText>}
            </Container>

            <FormControl sx={{ px: 3 }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                onChange={handleChangeRadioValue}
              >
                <FormControlLabel
                  value="disableDatePickers"
                  control={<Radio />}
                  label={` نمایش   ${name} منطبق با تاریخ شروع و پایان لیگ`}
                />
                <FormControlLabel
                  value="enableDatePickers"
                  control={<Radio />}
                  label={`نمایش   ${name} بر اساس انتخاب بازه زمانی`}
                />
              </RadioGroup>
            </FormControl>
            <Container title={`شروع نمایش ${name}`}>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  gap: "6rem",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  disable={radioValue === "disableDatePickers"}
                  onChange={(date: DateObject) => onDateChange(setFieldValue, "startDate", date)}
                  helperText={errors.startDate}
                  error={Boolean(errors.startDate)}
                />

                <SubItemContainer title="startTime" titleWidth="6rem">
                  <TimePicker
                    disable={disable}
                    onChange={(date: DateObject) => setFieldValue("startTime", formatDateObjToTime(date.toDate()))}
                    // onChange={(date: DateObject) => onDateChange(setFieldValue, "startTime", date)}
                  />
                </SubItemContainer>
              </Box>
            </Container>

            <Container title={`پایان نمایش ${name}`}>
              <Box
                sx={{
                  display: "flex",
                  gap: "6rem",
                  alignItems: "start",
                  my: 2,
                }}
              >
                <DatePicker
                  disable={radioValue === "disableDatePickers"}
                  onChange={(date: DateObject) => onDateChange(setFieldValue, "endDate", date)}
                  helperText={errors.endDate}
                  error={Boolean(errors.endDate)}
                />

                <SubItemContainer title="endTime" titleWidth="6rem">
                  <TimePicker
                    disable={disable}
                    onChange={(date: DateObject) => setFieldValue("endTime", formatDateObjToTime(date.toDate()))}
                    //onChange={(date: DateObject) => onDateChange(setFieldValue, "endTime", date)}
                    // helperText={errors.endTime}
                    // error={Boolean(errors.endTime)}
                  />
                </SubItemContainer>
              </Box>
            </Container>

            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                width: "600px",
                mx: "20rem",
                justifyContent: "space-between",
                my: 5,
                pb: 10,
              }}
            >
              <Button type="submit" variant="contained" sx={{ px: 10, py: 1.1 }}>
                {t("acceptAndAdd")}
              </Button>
              <Button
                onClick={() =>
                  resetForm({
                    values: {
                      ...values,
                      startDate: "",
                      endDate: "",
                      // startTime: "",
                      // endTime: "",
                      leagueId: "",
                    },
                  })
                }
                variant="contained"
                sx={{ px: 10, bgcolor: "#676872", py: 1.1 }}
              >
                {t("giveUp")}
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </PageContainer>
  );
};

export default NewLeagueSlider;
