import useSWR from "swr";
import moment from "jalali-moment";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { LeagueType } from "types/league";
import { array, date, number, object, string } from "yup";
import { ChangeEvent, MouseEvent, useState } from "react";
import { toast } from "react-toastify";
import { DateObject } from "react-multi-date-picker";
import { combineDateTime } from "utils/timeCombiner";
import { formatD } from "utils/date";
import { NumeralThousandGroupStyles, formatNumeral } from "cleave-zen";
import Cleave from "cleave.js/react";
import { createLeague, editLeague } from "api/league";

interface InitialValueType extends Omit<LeagueType, "entranceCoinRequired"> {
  endTime: Date | string;
  startTime: Date | string;
  entranceCoinRequired: string | number;
}

export const useNewLeague = (leagueId?: string, navigate?: (route: string) => void) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: games } = useSWR<{ gameVersions: any[]; count: number }>("/game/version?publishStatus=done");
  const gameList = games?.gameVersions.map((i: any) => ({
    gameId: i?.game?._id,
    gameTitle: i?.game?.title,
    gameCoverImage: i?.game?.coverImage?.url,
  }));

  const tagList = games?.gameVersions.map((i: any) => ({
    gameId: i?.game?._id,
    gameTags: i?.game?.tags,
  }));

  const validationSchema = object({
    title: string().required(t("_errors.requiredValue")),
    gameId: string().required(t("_errors.requiredValue")),
    startDate: date().required(t("_errors.requiredValue")),
    //startTime: date().required(t("_errors.requiredValue")),
    endDate: date().required(t("_errors.requiredValue")),
    //endTime: date().required(t("_errors.requiredValue")),
    description: string().required(t("_errors.requiredValue")),
    totalPrize: string().required(t("_errors.requiredValue")),
    //totalPrizedate: string().required(t("_errors.requiredValue")),
    //entranceCoinRequired: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
    prizes: array().of(
      object({
        fromRank: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
        toRank: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
        prize: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
        prizeType: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
      })
    ),
    bannerImageId: string().required(t("_errors.requiredValue")),
    gifId: string().required(t("_errors.requiredValue")),
  });

  const initialValues: any = {
    tags: [],
    title: "",
    gameId: "",
    description: "",
    bannerImageId: "",
    totalPrize: 10000000,
    prizes: [
      { fromRank: 1, toRank: 1, prize: "", prizeType: "cash" },
      //   { fromRank: 2, toRank: 2, prize: "", prizeType: "merchandise" },
      //   { fromRank: 3, toRank: 3, prize: "", prizeType: "cash" },
    ],
    showInSlider: false,
    endDate: new Date(),
    endTime: "03:03",
    startDate: new Date(),
    startTime: "04:05",
    gifId: "",
    entranceCoinRequired: "",
    colorCode: "",
    active: true,
  };
  // function formatNumber(value:any) {
  //   const cleaveInstance = new Cleave(null, {
  //     numeral: true,
  //     numeralThousandsGroupStyle: 'none',
  //   });

  //   cleaveInstance.setRawValue(value);
  //   return cleaveInstance.getRawValue();
  // }
  const openAddItemPopover: any = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAddItemPopover = () => {
    setAnchorEl(null);
  };

  const submitLeague = async (values: FormikValues) => {
    let startdate = combineDateTime(values.startTime, formatD(new Date(values.startDate)));

    let enddate = combineDateTime(values.endTime, formatD(new Date(values.endDate)));

    const data: any = {
      ...values,
      totalPrize: Number(values.totalPrize),
      startDate: startdate,
      endDate: enddate,
    };
    try {
      leagueId ? await editLeague(data, leagueId) : await createLeague(data);
      leagueId ? toast.success("با موفقیت آپدیت شد") : toast.success(t("_msg.successfulSave"));
      navigate && navigate("/leagues");
    } catch (err: any) {
      toast.error(err?.message?.data?.status);
    }
  };

  const getLeagueTime = (initDate: string, initTime: string) => {
    const date = moment(initDate).format("YYYY-MM-DD");
    const time = moment(initTime).format("HH:mm");
    return new Date(`${date} ${time}`);
  };

  const onNumericFieldChange =
    (setFieldValue: (name: string, value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    };

  const onAutocompleteChange = (setFieldValue: (name: string, value: string) => void, value: string, id: string) => {
    setFieldValue("gameId", id);
  };

  const onDateChange = (
    setFieldValue: (name: string, value: Date | string) => void,
    name: string,
    date: DateObject
  ) => {
    setFieldValue(name, date.toDate());
  };

  return {
    anchorEl,
    tagList,
    submitLeague,
    onDateChange,
    initialValues,
    validationSchema,
    openAddItemPopover,
    closeAddItemPopover,
    onAutocompleteChange,
    onNumericFieldChange,
    gameList: gameList || [],
  };
};
