import useSWR from "swr";
import moment from "jalali-moment";
import { FormikValues } from "formik";
import { ILeagueType, createSlider } from "api/league";
import { useTranslation } from "react-i18next";
import { PrizeType } from "types/league";
import { array, date, number, object, string } from "yup";
import { ChangeEvent, MouseEvent, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { toast } from "react-toastify";

export interface initialValuesType {
  type: "banner" | "slider" | "";
  leagueId: string;
  imageId: string;
  startDate: string | Date;
  endDate: string | Date;
  startTime?: string | Date;
  endTime?: string | Date;
  order: number;
}

const useNewSlider = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: leagues } = useSWR<{ leagues: ILeagueType[]; count: number }>("/leagues");
  const { data: games } = useSWR<{ gameVersions: any[]; count: number }>("/game/version?publishStatus=done");

  const gameList = games?.gameVersions.map((i: any) => ({
    gameId: i?.game?._id,
    gameTitle: i?.game?.title,
    gameCoverImage: i?.game?.coverImage?.url,
  }));
  const leagueList = leagues?.leagues?.map((i: any) => ({
    leagueId: i?._id,
    leagueTitle: i?.title,
    leagueCoverImage: i?.bannerImage?.url,
  }));

  const validationSchema = object({
    title: string().required(t("_errors.requiredValue")),
    leagueId: string().required(t("_errors.requiredValue")),
    startDate: date().required(t("_errors.requiredValue")),
    //startTime: date().required(t("_errors.requiredValue")),
    endDate: date().required(t("_errors.requiredValue")),
    //endTime: date().required(t("_errors.requiredValue")),
    description: string().required(t("_errors.requiredValue")),
    totalPrize: string().required(t("_errors.requiredValue")),

    prizes: array().of(
      object({
        fromRank: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
        toRank: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
        prize: number().typeError(t("_errors.shouldAddNumericValue")).required(t("_errors.requiredValue")),
      })
    ),
    imageId: string().required(t("_errors.requiredValue")),
    gifId: string().required(t("_errors.requiredValue")),
  });

  const initPrizes: PrizeType[] = [1, 2, 3].map((item) => ({
    prize: "",
    toRank: item,
    fromRank: item,
    isRange: false,
  }));

  const initialValues: initialValuesType = {
    type: "slider",
    leagueId: "",
    imageId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    order: 1,
  };
  const openAddItemPopover: any = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAddItemPopover = () => {
    setAnchorEl(null);
  };

  const submitLeague = async (values: FormikValues) => {
    const data: any = {
      ...values,
    };

    try {
      const res = await createSlider(data);
      toast.success(t("_msg.successfulSave"));
    } catch (err: any) {
      toast.error(err?.message?.data?.status);
    }
  };

  const getLeagueTime = (initDate: string, initTime: string) => {
    const date = moment(initDate).format("YYYY-MM-DD");
    const time = moment(initTime).format("HH:mm");

    return new Date(`${date} ${time}`);
  };

  const onNumericFieldChange =
    (setFieldValue: (name: string, value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      //setFieldValue(name, value.replace(REGEX.NUMBER, ""));
      setFieldValue(name, value);
    };

  const onAutocompleteChange = (setFieldValue: any, value: string, id: string) => {
    setFieldValue("leagueId", id);
  };

  const onDateChange = (
    setFieldValue: (name: string, value: Date | string) => void,
    name: string,
    date: DateObject
  ) => {
    setFieldValue(name, moment(date.toDate()).format("YYYY-MM-DD"));
  };

  return {
    leagues: leagues?.leagues,
    anchorEl,
    submitLeague,
    onDateChange,
    initialValues,
    validationSchema,
    openAddItemPopover,
    closeAddItemPopover,
    onAutocompleteChange,
    onNumericFieldChange,
    gameList: gameList || [],
    leagueList: leagueList || [],
  };
};

export default useNewSlider;
